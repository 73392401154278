<template>
  <header
    class="flex items-center justify-center w-full h-50vh border-b-8 all-products-bg border-primary"
  >
    <div class="font-medium tracking-wide text-center text-white md:w-1/2">
      <h1 class="m-3 text-4xl font-bold uppercase md:text-6xl mt-32">
        {{ $t('products.h1') }}
      </h1>
      <p class="text-2xl font-medium">{{ $t('products.description') }}</p>
    </div>
  </header>
  <div class="container mx-auto">
    <input
      v-if="products"
      type="search"
      v-model="search"
      :placeholder="$t('products.search')"
      class="w-full px-5 py-2 mt-16 text-gray-800 bg-white border rounded-lg outline-none border-dark"
    />
  </div>
  <div
    v-if="products"
    class="container grid grid-cols-1 gap-12 px-5 mx-auto my-16 md:px-8 sm:grid-cols-2 md:grid-cols-3"
  >
    <button
      @click="$router.push(`/product/${product.product_id}`)"
      v-for="product in searchOnProducts"
      :key="product.product_id"
      class="relative transition-all duration-300 drop-shadow-lg"
    >
      <img
        loading="lazy"
        alt=""
        :src="URL + product.image"
        class="object-cover w-full rounded-lg h-52"
      />
      <div class="p-5">
        <h4 class="mx-auto text-4xl text-center text-dark">
          {{ locale == 'ar' ? product.name_ar : product.name_en }}
        </h4>
      </div>
    </button>
  </div>
</template>
<script>
export default {
  name: 'Products',
  data() {
    return {
      // URL: process.env.VUE_APP_URL,
      URL: 'https://api.nowarat.ly/',
      search: '',
    }
  },
  computed: {
    products() {
      return this.$store.getters.products
    },
    locale() {
      return this.$i18n.locale
    },
    searchOnProducts() {
      return this.products.filter(
        (prod) => prod.name_en.match(this.search) || prod.name_ar.match(this.search)
      )
    },
  },
}
</script>
<style scoped>
.all-products-bg {
  background-image: url(../assets/allproducts.webp);
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.overloay {
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.4);
}
</style>
